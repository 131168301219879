import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PublicRoutes } from "./public-routes";
import { PrivateRoutes } from "./private-routes";
import LoginPage from "src/pages/public/login";
import ForgotPasswordPage from "src/pages/public/forgot-password";
import { Layout } from "src/shared/layout";
import ActiveEmployeesPage from "src/pages/private/employees/active";
import ActiveLocationsPage from "src/pages/private/locations/active";
import ArchivedEmployeesPage from "src/pages/private/employees/archived";
import AddEditEmployeePage from "src/pages/private/employees/add-edit-employee";
import ArchivedLocationsPage from "src/pages/private/locations/archived";
import AddEditLocationPage from "src/pages/private/locations/add-edit-location";
import TransactionsListPage from "src/pages/private/transactions/list";
import BITransactionsPage from "src/pages/private/business-intelligence/transactions";
import { doc, getDoc } from "firebase/firestore";
import { authentication, db } from "src/firebase";
import { getFirstPrivateRoute } from "src/utils";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";
import { IUserPermissions } from "src/types/generic";
import { LinearProgress } from "@mui/material";
import NoAccess from "src/shared/components/no-access";
import { signOut } from "firebase/auth";
import { notificationsState } from "src/store/notifications";
import LocationsProfilePage from "src/pages/private/locations/location-profile";
import ReportsSalesSummaryPage from "src/pages/private/reports/sales-summary";
import CustomersFeedbackPage from "src/pages/private/customers/feedback";
import ActiveClientsPage from "src/pages/private/clients/active";
import AddEditClientsPage from "src/pages/private/clients/add-edit-clients";
import ArchivedClientsPage from "src/pages/private/clients/archived";
import ClientProfilePage from "src/pages/private/clients/client-profile";
import TransactionsUpcomingListPage from "src/pages/private/transactions/upcoming";

function AllRoutes() {
  const [auth, setAuth] = useRecoilState(authState);
  const [permissions, setPermissions] = useState<IUserPermissions>();
  const [firstRouteToAccess, setFirstRouteToAccess] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      if (auth.user) {
        try {
          setLoading(true);
          console.log("auth user id:", auth.user.uid);
          const docRef = doc(db, "users", auth.user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("user data:", docSnap.data());
            // if no permissions sign user out
            if (!docSnap.data().permissions) {
              signOut(authentication)
                .then(() => {
                  // Sign-out successful.
                  setNotifications([
                    ...notifications,
                    {
                      type: "error",
                      message: "You have no permissions",
                    },
                  ]);
                  localStorage.clear();
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
              return;
            }
            setPermissions(docSnap.data().permissions);
            setFirstRouteToAccess(
              getFirstPrivateRoute(docSnap.data().permissions)
            );
            setAuth({ ...auth, permissions: docSnap.data().permissions });
          } else {
            console.log("No such user!");
            signOut(authentication)
              .then(() => {
                // Sign-out successful.
                localStorage.clear();
                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (error: any) {
          console.error("Error fetching user data:", { error });
          if (error.code === "permission-denied") {
            signOut(authentication)
              .then(() => {
                // Sign-out successful.
                localStorage.clear();
                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserPermissions();
  }, [auth.user]);

  return loading || (!!auth.user && !auth.permissions) ? (
    <LinearProgress />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          element={<PublicRoutes firstRouteToAccess={firstRouteToAccess} />}
        >
          <Route element={<LoginPage />} path="/login" />
          <Route element={<LoginPage />} path="/" />
          <Route element={<ForgotPasswordPage />} path="/forgot-password" />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />}>
            {/* locations */}
            <Route
              element={
                permissions?.locations?.view || loading ? (
                  <ActiveLocationsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/locations/active"
            />
            <Route
              element={
                permissions?.locations?.view || loading ? (
                  <ArchivedLocationsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/locations/archived"
            />
            <Route
              element={
                permissions?.locations?.create || loading ? (
                  <AddEditLocationPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/locations/active/add"
            />
            <Route
              element={
                permissions?.locations?.edit || loading ? (
                  <AddEditLocationPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/locations/active/:id/edit"
            />
            <Route
              element={
                permissions?.locations?.edit || loading ? (
                  <AddEditLocationPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/locations/archived/:id/edit"
            />
            {/* TODO: just UI; not integrated yet */}
            <Route
              element={<LocationsProfilePage />}
              path="/locations/:id/profile"
            />
            {/* employees */}
            <Route
              element={
                permissions?.arsannEmployees?.view || loading ? (
                  <ActiveEmployeesPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/employees/active"
            />
            <Route
              element={
                permissions?.arsannEmployees?.create || loading ? (
                  <AddEditEmployeePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/employees/active/add"
            />
            <Route
              element={
                permissions?.arsannEmployees?.edit || loading ? (
                  <AddEditEmployeePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/employees/active/:id/edit"
            />
            <Route
              element={
                permissions?.arsannEmployees?.edit || loading ? (
                  <AddEditEmployeePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/employees/archived/:id/edit"
            />
            <Route
              element={
                permissions?.arsannEmployees?.view || loading ? (
                  <ArchivedEmployeesPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/employees/archived"
            />
            {/* transactions */}
            <Route
              element={
                permissions?.transactions?.view || loading ? (
                  <TransactionsUpcomingListPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/transactions/upcoming"
            />
            <Route
              element={
                permissions?.transactions?.view || loading ? (
                  <TransactionsListPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/transactions/list"
            />

            {/* clients */}
            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ActiveClientsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active"
            />
            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ArchivedClientsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/archived"
            />
            <Route
              element={
                permissions?.clients?.create || loading ? (
                  <AddEditClientsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/add"
            />

            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ClientProfilePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:id"
            />

            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ClientProfilePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:id/contacts"
            />

            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ClientProfilePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:id/accounts"
            />

            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ClientProfilePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:id/locationList"
            />

            <Route
              element={
                permissions?.clients?.view || loading ? (
                  <ClientProfilePage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/archived/:id"
            />

            <Route
              element={
                permissions?.locations?.create || loading ? (
                  <AddEditLocationPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:clientId/add-location"
            />
            <Route
              element={
                permissions?.locations?.create || loading ? (
                  <AddEditLocationPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/clients/active/:clientId/locationList/:id/edit"
            />
            {/* Business Intelligence */}
            <Route
              element={
                permissions?.businessIntelligence?.viewTransactions ||
                loading ? (
                  <BITransactionsPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/business-intelligence/trans"
            />
            {/* Reports */}
            <Route
              element={
                permissions?.reports?.viewSalesSummary || loading ? (
                  <ReportsSalesSummaryPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/reports/sales"
            />

            <Route
              element={
                permissions?.customersFeedback?.view ? (
                  <CustomersFeedbackPage />
                ) : (
                  <NoAccess />
                )
              }
              path="/customers/feedback"
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
