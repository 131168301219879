import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  SvgIcon,
  ListItemText,
  Collapse,
  Box,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ReactComponent as DashboardIcon } from "src/assets/svg-icons/side-bar/dashboard.svg";
import { ReactComponent as LocationsIcon } from "src/assets/svg-icons/side-bar/location.svg";
import { ReactComponent as CustomersIcon } from "src/assets/svg-icons/side-bar/customers.svg";
import { ReactComponent as EmployeesIcon } from "src/assets/svg-icons/side-bar/employees.svg";
import { ReactComponent as ClientsIcon } from "src/assets/svg-icons/side-bar/clients.svg";
import { ReactComponent as BusinessIntIcon } from "src/assets/svg-icons/side-bar/business-intelligence.svg";
import { ReactComponent as ReportsIcon } from "src/assets/svg-icons/side-bar/reports.svg";
import { ReactComponent as TransactionsIcon } from "src/assets/svg-icons/side-bar/transactions.svg";
import LogoIcon from "src/assets/svg-icons/arsann-logo.svg";
import { authState } from "src/store/auth";
import { useRecoilState } from "recoil";

type INavItems =
  | "locations"
  | "clients"
  | "employees"
  | "transactions"
  | "business-intelligence"
  | "reports"
  | "customers";

export const NavItems: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;

  const [opened, setOpened] = useState<INavItems>(
    checkIncludingPath("locations")
      ? "locations"
      : checkIncludingPath("clients")
      ? "clients"
      : checkIncludingPath("employees")
      ? "employees"
      : checkIncludingPath("business-intelligence")
      ? "business-intelligence"
      : checkIncludingPath("reports")
      ? "reports"
      : checkIncludingPath("customers")
      ? "customers"
      : "transactions"
  );

  function checkIncludingPath(path: string) {
    return pathname.includes(path);
  }

  const checkMatchingPath = (path: string) => {
    return matchPath(path, pathname);
  };

  const handleClickOpen = (item: INavItems) => {
    setOpened(item);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Stack
      maxWidth={"290px"}
      minWidth={"290px"}
      pb={"15px"}
      height={"100vh"}
      maxHeight={"100vh"}
      position={"sticky"}
      top={"0"}
      bgcolor="background.paper"
    >
      <Box
        px={"25px"}
        py={"19px"}
        mb={"20px"}
        borderBottom={"1px solid #393945;"}
      >
        <img src={LogoIcon} width={61} alt="" />
      </Box>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          padding: "0 25px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {/* Reports */}
        {(permissions?.reports.viewSalesSummary ||
          permissions?.reports.viewPerformance ||
          permissions?.reports.viewAttendance) && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("reports")}
              sx={{
                color: checkIncludingPath("reports") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("reports") ? "primary" : "action"}
                >
                  <ReportsIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Reports" />
              {opened === "reports" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "reports"} timeout="auto" unmountOnExit>
              {permissions?.reports.viewSalesSummary && (
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      py: "6px",
                      "&:hover": {
                        backgroundColor: "rgba(204, 156, 51, 0.20)",
                      },
                      borderRadius: "9px",
                      backgroundColor: checkMatchingPath("reports/sales")
                        ? "rgba(204, 156, 51, 0.20)"
                        : "",
                    }}
                    onClick={() => handleNavigation("reports/sales")}
                  >
                    <ListItemIcon sx={{ minWidth: "20px" }}>
                      <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Sales Summary" />
                  </ListItemButton>
                </List>
              )}
            </Collapse>
          </>
        )}
        {/* Business Intelligence */}
        {(permissions?.businessIntelligence.viewSales ||
          permissions?.businessIntelligence.viewTransactions) && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("business-intelligence")}
              sx={{
                color: checkIncludingPath("business-intelligence")
                  ? "primary.main"
                  : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={
                    checkIncludingPath("business-intelligence")
                      ? "primary"
                      : "action"
                  }
                >
                  <BusinessIntIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Business Intelligence" />
              {opened === "business-intelligence" ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse
              in={opened === "business-intelligence"}
              timeout="auto"
              unmountOnExit
            >
              {permissions?.businessIntelligence.viewTransactions && (
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      py: "6px",
                      "&:hover": {
                        backgroundColor: "rgba(204, 156, 51, 0.20)",
                      },
                      borderRadius: "9px",
                      backgroundColor: checkMatchingPath(
                        "business-intelligence/trans"
                      )
                        ? "rgba(204, 156, 51, 0.20)"
                        : "",
                    }}
                    onClick={() =>
                      handleNavigation("business-intelligence/trans")
                    }
                  >
                    <ListItemIcon sx={{ minWidth: "20px" }}>
                      <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItemButton>
                </List>
              )}
            </Collapse>
          </>
        )}
        {/* Locations */}
        {permissions?.locations?.view && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("locations")}
              sx={{
                color: checkIncludingPath("locations") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("locations") ? "primary" : "action"}
                >
                  <LocationsIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Locations" />
              {opened === "locations" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "locations"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("locations/active")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("locations/active")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Active Locations" />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("locations/archived")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("locations/archived")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Archived Locations" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        {/* clients */}
        {permissions?.clients?.view && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("clients")}
              sx={{
                color: checkIncludingPath("clients") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("clients") ? "primary" : "action"}
                >
                  <ClientsIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Clients" />
              {opened === "locations" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "clients"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("clients/active")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("clients/active")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Active Clients" />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("clients/archived")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("clients/archived")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Archived Clients" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}

        {/* Transactions */}
        {permissions?.transactions?.view && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("transactions")}
              sx={{
                color: checkIncludingPath("transactions") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("transactions") ? "primary" : "action"}
                >
                  <TransactionsIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Transactions" />
              {opened === "transactions" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "transactions"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("transactions/upcoming")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("transactions/upcoming")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Upcoming" />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("transactions/list")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("transactions/list")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Transactions List" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        
        {/* Customers */}
        {permissions?.customersFeedback?.view && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("customers")}
              sx={{
                color: checkIncludingPath("customers") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("customers") ? "primary" : "action"}
                >
                  <CustomersIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Customers" />
              {opened === "customers" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "customers"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkMatchingPath("customers/feedback")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("customers/feedback")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Customers Feedback" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        {/* Employees */}
        {permissions?.arsannEmployees?.view && (
          <>
            <ListItemButton
              onClick={() => handleClickOpen("employees")}
              sx={{
                color: checkIncludingPath("employees") ? "primary.main" : "",
                "&:hover": { backgroundColor: "unset" },
                paddingX: "0",
                py: "6px",
              }}
            >
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <SvgIcon
                  color={checkIncludingPath("employees") ? "primary" : "action"}
                >
                  <EmployeesIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText color="primary" primary="Arsann Employees" />
              {opened === "employees" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={opened === "employees"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("employees/active")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("employees/active")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Active Employees" />
                </ListItemButton>

                <ListItemButton
                  sx={{
                    pl: 4,
                    py: "6px",
                    "&:hover": { backgroundColor: "rgba(204, 156, 51, 0.20)" },
                    borderRadius: "9px",
                    backgroundColor: checkIncludingPath("employees/archived")
                      ? "rgba(204, 156, 51, 0.20)"
                      : "",
                  }}
                  onClick={() => handleNavigation("employees/archived")}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Archived Employees" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Stack>
  );
};
