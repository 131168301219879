import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PaymentsLinearChart from "./payments";
import LocationsSalesChart from "./locations-sales";
import StatisticsBox from "./statistics-box";
import totalSalesIcon from "src/assets/svg-icons/total-sales.svg";
import paidIcon from "src/assets/svg-icons/paid.svg";
import freeIcon from "src/assets/svg-icons/free.svg";
import SalesTable from "./sales-table";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { formatNumber } from "src/utils";
import { useGetLocationsList } from "src/api/locations";
import { notificationsState } from "src/store/notifications";
import { useRecoilState } from "recoil";
import { Sync } from "@mui/icons-material";
import { useGetSalesSummary } from "src/api/sales";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportsSalesSummaryPage = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [tempDateRange, setTempDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [tempSelectedLocations, setTempSelectedLocations] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string>("all");
  const [focusedInput, setFocusedInput] = useState(null);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const filters = {
    ...(selectedLocations.length > 0 && {
      locations: selectedLocations.map((loc) => loc.nameEn),
    }),
    ...(selectedService !== "all" && { services: [selectedService] }),
    ...(dateRange.startDate && {
      startDate: `${new Date(dateRange.startDate).getTime()}`,
    }),
    ...(dateRange.endDate && {
      endDate: `${new Date(dateRange.endDate).getTime()}`,
    }),
  };

  // APIS
  const { data: locationsData } = useGetLocationsList();
  const { data, isLoading, refetch, error, status, isFetching } =
    useGetSalesSummary(filters);

  const handleChangeLocations = (value: any[], reason: string) => {
    setTempSelectedLocations(value);

    if (reason === "clear") {
      setSelectedLocations([]); // Clear the input value
    }
  };

  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };

  // Effect to save the date range when the date picker is closed
  useEffect(() => {
    if (focusedInput === null) {
      // Date picker is closed, now update the dateRange state
      setDateRange(tempDateRange);
    }
  }, [focusedInput, tempDateRange, dateRange]);

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations.sort((a: any, b: any) =>
        a.nameEn?.localeCompare(b.nameEn)
      ) || []
    );
  }, [locationsData]);

  useEffect(() => {
    if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.details?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  console.log({ data, isLoading, error, isFetching, dateRange });

  return (
    <Box p={{ xs: "20px 12px", md: "30px 20px", lg: "30px 30px" }}>
      <Typography fontWeight={600} fontSize={"22px"} mb={{ xs: 2, md: 4 }}>
        Sales Summary
      </Typography>

      {/* filters */}
      <Stack direction={"row"} gap={"10px"} flexWrap={"wrap"} mb={3}>
        {/* locations */}
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "20%" } }}
          size="small"
          limitTags={1}
          multiple
          id="tags-outlined"
          options={sortedLocations}
          disableCloseOnSelect
          getOptionLabel={(option: any) => option.nameEn}
          getOptionKey={(option: any) => option.id}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={`${props.id} ${option.id}`}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Typography>{option.nameEn}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Location" placeholder="" />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option: any, index: number) => (
              <Chip
                size="small"
                label={option.nameEn}
                {...getTagProps({ index })}
                key={option.id}
                onDelete={undefined} // Disable the "x" delete icon
              />
            ))
          }
          onChange={(event, value, reason) => {
            handleChangeLocations(value, reason);
          }}
          onClose={() => {
            setSelectedLocations(tempSelectedLocations);
          }}
        />
        {/* service */}
        <FormControl
          fullWidth
          size="small"
          sx={{ width: { xs: "100%", sm: "15%" } }}
        >
          <InputLabel id="demo-simple-select-label">Service</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            label="Service"
            onChange={handleChangeServiceType}
            fullWidth
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"valet"}>Valet</MenuItem>
            <MenuItem value={"selfParking"}>Self Parking</MenuItem>
          </Select>
        </FormControl>
        {/* date range */}
        <Box sx={{ minWidth: 183 }}>
          <DateRangeInput
            startDate={
              tempDateRange.startDate ? moment(tempDateRange.startDate) : null
            } // moment.Moment | null
            startDateId={`your_unique_start_date_id`}
            endDate={
              tempDateRange.endDate ? moment(tempDateRange.endDate) : null
            } // moment.Moment | null
            endDateId={`your_unique_end_date_id`}
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setTempDateRange({
                startDate: arg.startDate?.format("YYYY-MM-DD") as string,
                endDate: arg.endDate?.format("YYYY-MM-DD") as string,
              });
              if (arg.startDate === null && arg.endDate === null) {
                setDateRange({
                  startDate: "",
                  endDate: "",
                });
              }
            }}
            onFocusChange={(focused: any) => setFocusedInput(focused)}
          />
        </Box>
        {isFetching &&
          !isLoading &&
          (moment(dateRange.startDate).isSame(moment(), "day") ||
            moment(dateRange.endDate).isSame(moment(), "day")) && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              flexGrow={1}
              alignItems={"center"}
              gap={1}
            >
              <Typography>Syncing today's sales</Typography>

              <Sync
                sx={{
                  animation: "spin 1s linear infinite",
                  "@keyframes spin": {
                    "0%": { transform: "rotate(0deg)" },
                    "100%": { transform: "rotate(360deg)" },
                  },
                  fontSize: "1rem",
                }}
              />
            </Box>
          )}
      </Stack>

      {!data || isLoading ? (
        <Stack gap={0.9} pb={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map(
            (item) => (
              <Skeleton
                key={item}
                width={"100%"}
                height={"40px"}
                variant="rectangular"
              />
            )
          )}
        </Stack>
      ) : (
        <>
          <PaymentsLinearChart data={data?.data.salesByDay} />
          <Grid container mt={0} spacing={"20px"} mb={"20px"}>
            <Grid item xs={12} lg={8}>
              <LocationsSalesChart data={data.data.salesByLocation} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              display={"flex"}
              direction={"column"}
              gap={"10px"}
            >
              <StatisticsBox
                label="Total Sales"
                value={`SAR ${formatNumber(
                  data.data.overallSummary.totalSales
                )}`}
                icon={totalSalesIcon}
              />
              <StatisticsBox
                label="Paid Tickets"
                value={`${formatNumber(
                  data.data.overallSummary.ticketPaidCount
                )}`}
                icon={paidIcon}
              />
              <StatisticsBox
                label="Free Tickets"
                value={`${formatNumber(
                  data.data.overallSummary.freeTicketCount
                )}`}
                icon={freeIcon}
              />
            </Grid>
          </Grid>
        </>
      )}
      <SalesTable
        dateRange={dateRange}
        selectedLocations={selectedLocations}
        selectedService={selectedService}
      />
    </Box>
  );
};

export default ReportsSalesSummaryPage;
