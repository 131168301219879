import { Box, Typography } from "@mui/material";
import ActiveClientsTable from "./table";
import { useGetClientsList } from "src/api/clients";

const ActiveClientsPage = () => {
  // APIS
  const { data, isFetching, refetch } = useGetClientsList({ type: "active" });

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Active Clients
      </Typography>
      <ActiveClientsTable
        rowsList={data?.data || []}
        isLoading={isFetching}
        refetch={refetch}
      />
    </Box>
  );
};

export default ActiveClientsPage;
