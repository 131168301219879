import { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import StatusChip from "src/shared/components/status-chip";
import { getFirstAndLastLetters } from "src/utils";
import { IClientResponse } from "src/types/clients";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "src/firebase";
import { IEnumData } from "src/types/generic";

const tableHeadText = [
  "Name in English",
  "Name in Arabic",
  "NO. of Locations",
  "Agreement Type",
  "Valet",
  "Self-Parking",
  "Actions",
];

const ArchivedClientsTable = ({
  rowsList,
  isLoading,
  refetch,
}: {
  rowsList: IClientResponse[];
  isLoading: boolean;
  refetch: Function;
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState<string>("");
  const [selectedService, setSelectedService] = useState<any>("all");
  const [agreementTypes, setAgreementTypes] = useState<IEnumData[]>([]);
  const [selectedAgreement, setSelectedAgreement] = useState<any>("all");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (searchedVal: string) => {
    setPage(0);
    setSearchBy(searchedVal);
  };

  const clearSearch = () => {
    if (searchBy !== "") {
      handleSearch("");
    }
  };

  const handleChangeServiceType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedService(value);
  };

  const handleChangeAgreementType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedAgreement(value);
  };

  const handleSearchAndFilters = () => {
    let searchAndFiltersRows = [...rowsList];

    //Search by Arabic or English name
    if (!!searchBy.trim()) {
      const filteredRows = searchAndFiltersRows.filter((row) => {
        return (
          row.clientInfo?.nameEn
            ?.toLowerCase()
            .includes(searchBy.toLowerCase()) ||
          row.clientInfo?.nameAr?.toLowerCase().includes(searchBy.toLowerCase())
        );
      });
      searchAndFiltersRows = filteredRows;
    }

    // Filter by service
    if (selectedService === "valet") {
      const filteredRows = searchAndFiltersRows.filter((row) => {
        return row.clientInfo.hasValetService;
      });
      searchAndFiltersRows = filteredRows;
    } else if (selectedService === "selfParking") {
      const filteredRows = searchAndFiltersRows.filter((row) => {
        return row.clientInfo.hasSelfParkingService;
      });
      searchAndFiltersRows = filteredRows;
    }

    //Filter by agreement
    if (selectedAgreement !== "all") {
      const filteredRows = searchAndFiltersRows.filter((row) => {
        return row.clientInfo?.agreementType === selectedAgreement;
      });
      searchAndFiltersRows = filteredRows;
    }

    return searchAndFiltersRows;
  };

  const searchAndFiltersLocations = handleSearchAndFilters();

  const paginatedVisibleRows = useMemo(
    () =>
      searchAndFiltersLocations.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, rowsList, searchBy, selectedService, selectedAgreement] // filters changes rerender
  );

  const emptyDataArr = searchAndFiltersLocations.length === 0 && !isLoading;

  useEffect(() => {
    setPage(0);
  }, [selectedService, selectedAgreement]);

  // Fetch agreement types enum
  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchData = async () => {
      try {
        const docRef = doc(db, "enums", "clientAgreementTypes");

        unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setAgreementTypes(data.data);
          } else {
            console.warn("No such enum document found!");
          }
        });
      } catch (error) {
        console.error("Error fetching enum:", error);
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <>
      <Stack direction={"row"} gap={2} mb={4} flexWrap={"wrap"}>
        <TextField
          size="small"
          placeholder="Search"
          value={searchBy}
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ opacity: !searchBy ? 0 : 1 }}
              >
                <IconButton size="small" onClick={clearSearch}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControl size="small" sx={{ minWidth: "180px" }}>
          <InputLabel id="demo-simple-select-label">Service</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            label="Service"
            onChange={handleChangeServiceType}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {["valet", "selfParking"].map((service: any) => (
              <MenuItem key={service} value={service}>
                {service === "valet" ? "Valet" : "Self Parking"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: "180px" }}>
          <InputLabel id="demo-simple-select-label">Agreement Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAgreement}
            label="Agreement Type"
            onChange={handleChangeAgreementType}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {["Renting", "Revenue Sharing"].map((type: any) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <TableContainer
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#A1A5B7",
                  }}
                  align={item === "Actions" ? "center" : "left"}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={7} />
          ) : (
            <TableBody>
              {paginatedVisibleRows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{row.clientInfo?.nameEn}</TableCell>
                  <TableCell>{row.clientInfo?.nameAr}</TableCell>
                  <TableCell>{row.clientInfo.numberOfLocations}</TableCell>
                  <TableCell>{row.clientInfo?.agreementTypeLabel}</TableCell>
                  <TableCell>
                    <StatusChip
                      color={
                        row.clientInfo.hasValetService ? "success" : "warning"
                      }
                      label={
                        row.clientInfo.hasValetService ? "ACTIVE" : "INACTIVE"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <StatusChip
                      label={
                        row.clientInfo.hasSelfParkingService
                          ? "ACTIVE"
                          : "INACTIVE"
                      }
                      color={
                        row.clientInfo.hasSelfParkingService
                          ? "success"
                          : "warning"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Stack alignItems={"center"}>
                      <TableActions
                        rowId={row.id as string}
                        refetch={refetch}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {emptyDataArr && <NoData />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchAndFiltersLocations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};
export default ArchivedClientsTable;
