import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import { Box, Button } from "@mui/material";
import assignIcon from "src/assets/svg-icons/assign.svg";
import AddEditUserPopup from "./add-edit-user-popup";
import { IUserAccount } from "src/types/clients";

const tableHeadText = ["Name", "Email", "Actions"];

const rows = ["Test 1", "Test 2", "Test 3"];

interface IProps {
  userAccounts: IUserAccount[];
  refetch: Function;
  status: "archived" | "active";
}

const AccountUsers = (props: IProps) => {
  const { userAccounts, refetch, status } = props;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const noData = userAccounts.length === 0;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box mt={{ xs: "-40px", lg: "-90px" }}>
      <Box display={"flex"} justifyContent={"flex-end"} pt={"30px"} mb={3}>
        <Button
          onClick={handleOpen}
          startIcon={<img src={assignIcon} alt="" />}
          variant="contained"
          size="small"
          disabled={userAccounts.length >= 5 || status === "archived"}
        >
          Add New Client Account User
        </Button>
      </Box>
      <Box
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeadText.map((item) => (
                  <TableCell
                    key={item}
                    sx={{
                      fontWeight: 600,
                      color: "#A1A5B7",
                    }}
                    align={item === "Actions" ? "right" : "left"}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableLoadingSkeleton rowsLength={4} cellsLength={2} />
            ) : (
              <TableBody>
                {userAccounts.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>
                      <TableActions
                        rowId={row.id as string}
                        refetch={refetch}
                        disabled={status === "archived"}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {noData && <NoData subtitle="" />}
        </TableContainer>

        {open && (
          <AddEditUserPopup
            open={open}
            onClose={handleClose}
            refetch={refetch}
          />
        )}
      </Box>
    </Box>
  );
};
export default AccountUsers;
