import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import { useUnassignLocationFromClientMutation } from "src/api/clients";
import { useArchiveLocationMutation } from "src/api/locations";

const TableActions = ({
  rowId,
  disabled,
  refetch,
  refetchLocations
}: {
  rowId: string;
  disabled: boolean;
  refetch: Function;
  refetchLocations: Function
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openPopup, setOpenPopup] = useState(false);
  const [openArchivePopup, setOpenArchivePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // APIs
  const { mutate, isPending, status, error } =
    useUnassignLocationFromClientMutation();
  const {
    mutate: mutateArchive,
    status: archivingStatus,
    error: archivingError,
    isPending: isPendingArchiving,
  } = useArchiveLocationMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = () => {
    handleClose();
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleOpenArchivePopup = () => {
    handleClose();
    setOpenArchivePopup(true);
  };

  const handleCloseArchivePopup = () => {
    setOpenArchivePopup(false);
  };

  const handleUnassign = () => {
    mutate({ clientId: id as string, locationId: rowId });
  };

  const handleArchive = (note: string) => {
    mutateArchive(rowId);
  };

  const handleNavigateToEditPage = () => {
    navigate(`/clients/active/${id}/locationList/${rowId}/edit`);
  };

  //Unassign success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      refetchLocations();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The location has been unassigned successfully!",
        },
      ]);
      handleClosePopup();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  //Archive success and error handling
  useEffect(() => {
    if (archivingStatus === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "Archived successfully",
        },
      ]);
      handleCloseArchivePopup();
    } else if (archivingStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: archivingError?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [archivingStatus]);

  return (
    <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
      <LoadingButton
        variant="contained"
        color="inherit"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        size="small"
        sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
        disabled={disabled}
      >
        Actions
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleNavigateToEditPage}
          sx={{ width: "105px", color: "primary.main" }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenPopup}>Unassign</MenuItem>
        <MenuItem onClick={handleOpenArchivePopup}>Archive</MenuItem>
      </Menu>

      {/* confirm unassign */}
      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="This action will unassign this location from the client's locations"
        submitBtnLabel="Unassign"
        onSave={handleUnassign}
        isLoading={isPending}
      />

      {/* confirm archiving */}
      <ConfirmPopup
        open={openArchivePopup}
        setOpen={setOpenArchivePopup}
        handleClose={handleCloseArchivePopup}
        subtitle="This action will remove this location from active list and add it to
              archive list"
        submitBtnLabel="Archive"
        onSave={handleArchive}
        isLoading={isPendingArchiving}
      />
    </Stack>
  );
};
export default TableActions;
