import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import AccountUsers from "./user-accounts";
import Locations from "./locations";
import ContactPersons from "./contact-persons";
import { IContactPerson, IUserAccount } from "src/types/clients";
import { ILocation } from "src/types/locations";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"100%"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps {
  contactPersons: IContactPerson[];
  userAccounts: IUserAccount[];
  locations: ILocation[];
  refetch: Function;
  status: "archived" | "active";
  clientName: string;
  agreementType: string;
}

const ClientProfileTabs = (props: IProps) => {
  const {
    contactPersons,
    userAccounts,
    locations,
    refetch,
    status,
    clientName,
    agreementType,
  } = props;
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  // Map paths to tab indices
  const tabPaths = [
    `/clients/active/${id}/contacts`,
    ...(agreementType === "revenueSharing"
      ? [`/clients/active/${id}/accounts`]
      : []),
    `/clients/active/${id}/locationList`,
  ];
  const currentPath = location.pathname;
  // Find the tab index based on the current URL path
  const currentTabIndex = tabPaths.indexOf(currentPath);
  const [value, setValue] = useState(
    currentTabIndex === -1 ? 0 : currentTabIndex
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(tabPaths[newValue]); // Change the URL based on the selected tab
  };

  // Update the tab value when the URL changes
  useEffect(() => {
    const newTabIndex = tabPaths.indexOf(currentPath);
    if (newTabIndex !== value) {
      setValue(newTabIndex === -1 ? 0 : newTabIndex);
    }
  }, [currentPath]);

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{
            minHeight: { xs: "100%", md: "44px" },
            height: { xs: "100%", md: "44px" },
            bgcolor: "#232323",
            borderRadius: "10px",
          }}
        >
          <Tab
            disableRipple
            sx={{
              color: "#FFFFFF",
              height: "44px",
              minHeight: "44px",
              textTransform: "unset",
              "&.Mui-selected": {
                bgcolor: "#CC9C33",
                color: "#000000",
                borderRadius: "9px",
                fontWeight: 600,
              },
            }}
            label={"Contact Persons"}
            {...a11yProps(0)}
          />
          {agreementType === "revenueSharing" && (
            <Tab
              disableRipple
              sx={{
                color: "#FFFFFF",
                height: "44px",
                minHeight: "44px",
                textTransform: "unset",
                "&.Mui-selected": {
                  bgcolor: "#CC9C33",
                  color: "#000000",
                  borderRadius: "9px",
                  fontWeight: 600,
                },
              }}
              label={"Client Account Users"}
              {...a11yProps(1)}
            />
          )}
          <Tab
            disableRipple
            sx={{
              color: "#FFFFFF",
              height: "44px",
              minHeight: "44px",
              textTransform: "unset",
              "&.Mui-selected": {
                bgcolor: "#CC9C33",
                color: "#000000",
                borderRadius: "9px",
                fontWeight: 600,
              },
            }}
            label={"Locations"}
            {...a11yProps(agreementType === "revenueSharing" ? 2 : 1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ContactPersons
          contactPersons={contactPersons}
          status={status}
          refetch={refetch}
        />
      </CustomTabPanel>
      {agreementType === "revenueSharing" && (
        <CustomTabPanel value={value} index={1}>
          <AccountUsers
            userAccounts={userAccounts}
            status={status}
            refetch={refetch}
          />
        </CustomTabPanel>
      )}
      <CustomTabPanel
        value={value}
        index={agreementType === "revenueSharing" ? 2 : 1}
      >
        <Locations
          locations={locations}
          status={status}
          refetch={refetch}
          clientName={clientName}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default ClientProfileTabs;
