import { Menu, MenuItem, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import { useDeleteClientAccountMutation } from "src/api/clients";

const TableActions = ({
  rowId,
  refetch,
  disabled
}: {
  rowId: string;
  refetch: Function;
  disabled: boolean
}) => {
  const { id } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // APIs
  const { mutate, status, isPending, error } = useDeleteClientAccountMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
    handleClose();
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleDelete = () => {
    mutate({ clientId: `${id}`, accountId: rowId });
  };

  //delete success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The user account has been deleted successfully!",
        },
      ]);
      handleClosePopup();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
      <LoadingButton
        variant="contained"
        color="inherit"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        size="small"
        sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
        disabled={disabled}
      >
        Actions
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenPopup}>Delete</MenuItem>
      </Menu>

      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="This action will remove this user account from the client's accounts list and prevent the user access to the client dashboard."
        submitBtnLabel="Delete"
        onSave={handleDelete}
        isLoading={isPending}
      />
    </Stack>
  );
};
export default TableActions;
