import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import AddEditContactPersonPopup from "../add-edit-contact-popup";
import { IContactPerson } from "src/types/clients";
import { useDeleteContactPersonMutation } from "src/api/clients";

const TableActions = ({
  row,
  refetch,
  disabled,
}: {
  row: IContactPerson;
  refetch: Function;
  disabled: boolean;
}) => {
  const { id: contactId } = row;
  const { id: clientId } = useParams();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // APIs
  const { mutate, status, isPending, error } = useDeleteContactPersonMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeletePopup = () => {
    setOpenDeletePopup(true);
    handleClose();
  };
  const handleCloseDeletePopup = () => setOpenDeletePopup(false);

  const handleOpenEditPopup = () => {
    setOpenEditPopup(true);
    handleClose();
  };

  const handleCloseEditPopup = () => setOpenEditPopup(false);

  const handleDelete = () => {
    mutate({ clientId: `${clientId}`, contactId: `${contactId}` });
  };

  //delete success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The contact person has been deleted successfully!",
        },
      ]);
      handleCloseDeletePopup();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
      <LoadingButton
        variant="contained"
        color="inherit"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        size="small"
        sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
        disabled={disabled}
      >
        Actions
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleOpenEditPopup}
          sx={{ width: "105px", color: "primary.main" }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleOpenDeletePopup}>Delete</MenuItem>
      </Menu>

      <ConfirmPopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        handleClose={handleCloseDeletePopup}
        subtitle="Are you sure you want to delete this contact person?"
        submitBtnLabel="Delete"
        onSave={handleDelete}
        isLoading={isPending}
      />

      {openEditPopup && (
        <AddEditContactPersonPopup
          open={openEditPopup}
          onClose={handleCloseEditPopup}
          refetch={refetch}
          dataToEdit={row}
        />
      )}
    </Stack>
  );
};
export default TableActions;
