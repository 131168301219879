import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import EditMainInfoPopup from "../edit-info-popup";
import { useState } from "react";
import moment from "moment";
import { getFirstAndLastLetters } from "src/utils";
import { useArchiveClientMutation } from "src/api/clients";
import ArchiveActivatePopup from "../archive-activate-popup";
import { useParams } from "react-router-dom";
import ActivatePopup from "../../activate-popup";

const MainInfo = ({
  mainInfo,
  refetch,
  clientStatus,
}: {
  mainInfo: {
    agreementTypeLabel: string; //"Revenue Sharing" | "Renting"
    startDate: string;
    endDate: string;
    nameAr: string;
    nameEn: string;
  };
  refetch: Function;
  clientStatus: "archived" | "active";
}) => {
  const { id } = useParams();
  const { agreementTypeLabel, startDate, endDate, nameAr, nameEn } = mainInfo;
  const [open, setOpen] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);

  // APIs
  const { mutate, isPending, status, error } = useArchiveClientMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenArchive = () => setOpenArchive(true);
  const handleCloseArchive = () => setOpenArchive(false);

  const handleOpenActivate = () => setOpenActivate(true);
  const handleCloseActivate = () => setOpenActivate(false);

  return (
    <Stack spacing={3} height={"100%"}>
      <Stack
        direction={"row"}
        spacing={"11px"}
        display={"flex"}
        alignItems={"center"}
      >
        <Avatar
          sx={{
            background: "linear-gradient(180deg, #656464 0%, #373737 100%)",
            width: "60px",
            height: "60px",
            color: "#FFFFFF",
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: "22px",
            textTransform: "uppercase",
          }}
        >
          {getFirstAndLastLetters(nameEn)}
        </Avatar>
        <Box>
          <Typography
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#FFFFFF"}
            mb={"5px"}
          >
            {nameEn}
          </Typography>
          <Typography
            fontSize={"14px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#ACA8A8"}
          >
            {agreementTypeLabel} Agreement Type
          </Typography>
        </Box>
      </Stack>

      {/* divider */}
      <Box bgcolor={"#454343"} width={"100%"} height={"1px"} />

      <Stack spacing={"5px"}>
        <Typography
          fontSize={"14px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          Name in Arabic
        </Typography>
        <Typography
          fontSize={"14px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          {nameAr}
        </Typography>
      </Stack>
      <Stack spacing={"5px"}>
        <Typography
          fontSize={"14px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          Start Date
        </Typography>
        <Typography
          fontSize={"14px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          {startDate ? moment(Number(startDate)).format("DD/MM/yyyy") : "--"}
        </Typography>
      </Stack>
      <Stack spacing={"5px"}>
        <Typography
          fontSize={"14px"}
          lineHeight={"16px"}
          fontWeight={600}
          color={"#FFFFFF"}
        >
          End Date
        </Typography>
        <Typography
          fontSize={"14px"}
          lineHeight={"14px"}
          fontWeight={600}
          color={"#ACA8A8"}
        >
          {endDate ? moment(Number(endDate)).format("DD/MM/yyyy") : "--"}
        </Typography>
      </Stack>

      <Box flexGrow={1} display={"flex"} justifyContent={"space-between"}>
        {clientStatus === "active" ? (
          <>
            <Button
              variant="outlined"
              sx={{ padding: "12px 16px", borderRadius: "6px", mt: "auto" }}
              onClick={handleOpen}
            >
              Edit Main Info
            </Button>
            <Button
              variant="contained"
              sx={{ padding: "12px 16px", borderRadius: "6px", mt: "auto" }}
              onClick={handleOpenArchive}
            >
              Archive
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{
              padding: "12px 16px",
              borderRadius: "6px",
              mt: "auto",
              ml: "auto",
            }}
            onClick={handleOpenActivate}
          >
            Activate
          </Button>
        )}
      </Box>

      {open && (
        <EditMainInfoPopup
          id={id as string}
          dataToEdit={mainInfo}
          open={open}
          onClose={handleClose}
          refetch={refetch}
        />
      )}

      {openArchive && (
        <ArchiveActivatePopup
          open={openArchive}
          onClose={handleCloseArchive}
          refetch={refetch}
          clientId={id as string}
        />
      )}

      {openActivate && (
        <ActivatePopup
          open={openActivate}
          onClose={handleCloseActivate}
          refetch={refetch}
          clientId={id as string}
        />
      )}
    </Stack>
  );
};

export default MainInfo;
