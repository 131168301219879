import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import ClosIcon from "src/assets/svg-icons/close.svg";
import { useAddClientAccountMutation } from "src/api/clients";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .max(100, "Can not exceed 100 characters"),
  email: yup
    .string()
    .required("Required")
    .max(100, "Can not exceed 100 characters")
    .test("is-valid-email", "Invalid Email", (value) => {
      if (!value) return true;
      const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
      return emailRegex.test(value);
    }),
});

export interface IFormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  onClose: any;
  refetch: Function;
}

const AddEditUserPopup: React.FC<IProps> = (props) => {
  const { open, onClose, refetch } = props;
  const { id } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // APIs
  const { mutate, status, isPending, error } = useAddClientAccountMutation();

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const dataToSend = {
      id,
      ...data,
    };
    mutate(dataToSend);
  };

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The user account has been added successfully!",
        },
      ]);
      handleClose();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  console.log({ errors, error });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      component={"div"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          bgcolor: "#191919",
        },
      }}
    >
      <DialogTitle sx={{ p: "30px 30px 20px 30px" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography fontSize={"22px"} fontWeight={600} lineHeight={"22px"}>
            Add New Client Account User
          </Typography>
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
            <img src={ClosIcon} alt="" />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px={"30px"} gap={"21px"}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name"
                fullWidth
                size="medium"
                {...field}
                error={!!errors.name}
                helperText={errors.name?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                fullWidth
                size="medium"
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
        </Stack>

        <DialogActions sx={{ padding: "31px", gap: "12px" }}>
          <Button variant="outlined" type="submit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditUserPopup;
