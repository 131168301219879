import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("Required"),
  arabicName: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  nationalId: yup.string(),
  phone: yup.string().required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

export const initAdminPermissions = [
  {
    name: "reports",
    label: "Reports",
    permissions: [
      {
        id: "viewSalesSummary",
        label: "Sales Summary",
        checked: false,
      },
      // {
      //   id: "viewAttendance",
      //   label: "Attendance",
      //   checked: false,
      // },
      // {
      //   id: "viewPerformance",
      //   label: "Performance",
      //   checked: false,
      // },
    ],
  },
  {
    name: "businessIntelligence",
    label: "Business Intelligence",
    permissions: [
      {
        id: "viewTransactions",
        label: "Transactions",
        checked: false,
      },
      // {
      //   id: "viewSales",
      //   label: "View Sales",
      //   checked: false,
      // },
    ],
  },
  {
    name: "locations",
    label: "Locations",
    permissions: [
      {
        id: "view",
        label: "View",
        checked: false,
      },
      {
        id: "create",
        label: "Create",
        checked: false,
      },
      {
        id: "edit",
        label: "Edit",
        checked: false,
      },
    ],
  },
  {
    name: "clients",
    label: "Clients",
    permissions: [
      {
        id: "view",
        label: "View",
        checked: false,
      },
      {
        id: "create",
        label: "Create",
        checked: false,
      },
      {
        id: "edit",
        label: "Edit",
        checked: false,
      },
    ],
  },
  // {
  //   name: "customers",
  //   label: "Customers",
  //   permissions: [
  //     {
  //       id: "view",
  //       label: "View",
  //       checked: false,
  //     },
  //     {
  //       id: "edit",
  //       label: "Edit",
  //       checked: false,
  //     },
  //   ],
  // },
  {
    name: "transactions",
    label: "Transactions",
    permissions: [
      {
        id: "view",
        label: "View",
        checked: false,
      },

      {
        id: "edit",
        label: "Edit",
        checked: false,
      },
    ],
  },
  {
    name: "customersFeedback",
    label: "Customers Feedback",
    permissions: [
      {
        id: "view",
        label: "View",
        checked: false,
      },
    ],
  },
  {
    name: "arsannEmployees",
    label: "Arsann Employees",
    permissions: [
      {
        id: "view",
        label: "View",
        checked: false,
      },
      {
        id: "create",
        label: "Create",
        checked: false,
      },
      {
        id: "edit",
        label: "Edit",
        checked: false,
      },
    ],
  },
];
