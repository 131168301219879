import { Box, Typography } from "@mui/material";
import React from "react";

interface IPropsTypes {
  subtitle?: string;
}

export const NoData: React.FC<IPropsTypes> = (props) => {
  const {
    subtitle = "Double check for your search entry or reset your applied filters",
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={!!subtitle ? 290 : 320}
      p={3}
    >
      <Typography fontWeight={600} variant="h6" textAlign={"center"}>
        No data
      </Typography>

      <Typography textAlign={"center"}>{subtitle}</Typography>
    </Box>
  );
};
