import { Box, Typography } from "@mui/material";
import ArchivedClientsTable from "./table";
import { useGetClientsList } from "src/api/clients";

const ArchivedClientsPage = () => {
  // APIS
  const { data, isFetching, refetch } = useGetClientsList({ type: "archived" });

  return (
    <Box p={"30px"}>
      <Typography mb={4} fontSize={"22px"} fontWeight={600}>
        Archived Clients
      </Typography>
      <ArchivedClientsTable
        rowsList={data?.data || []}
        isLoading={isFetching}
        refetch={refetch}
      />
    </Box>
  );
};

export default ArchivedClientsPage;
