import { Box, Typography } from "@mui/material";
import TransactionsTable from "./table";

const TransactionsUpcomingListPage = () => {
  return (
    <Box p={"30px"}>
      <Typography mb={{xs: 2, md :4}} fontSize={"22px"} fontWeight={600}>
        Upcoming Transactions
      </Typography>
      <TransactionsTable />
    </Box>
  );
};

export default TransactionsUpcomingListPage;
