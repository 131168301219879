import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMemo, useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Button, Stack } from "@mui/material";
import assignIcon from "src/assets/svg-icons/assign.svg";
import TableActions from "./table-actions";
import AssignLocationPopup from "./assign-location";
import correctIcon from "src/assets/svg-icons/correct.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ILocation } from "src/types/locations";
import StatusChip from "src/shared/components/status-chip";
import { useTheme, useMediaQuery } from "@mui/material";
import { useGetLocationsList } from "src/api/locations";

const tableHeadText = [
  "Name in English",
  "Name in Arabic",
  "Valet",
  "Self-Parking",
  "Actions",
];

const Locations = ({
  locations,
  refetch,
  status,
  clientName,
}: {
  locations: ILocation[];
  refetch: Function;
  status: "archived" | "active";
  clientName: string;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSpecificWidth = useMediaQuery("(min-width:1400px)");

  // APIs
  const {
    data: locationsData,
    isFetching: isFetchingLocations,
    refetch: refetchLocations,
  } = useGetLocationsList();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigateToAddLocation = () => {
    navigate(`/clients/active/${id}/add-location?client=${clientName}`);
  };

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations
        .filter((loc: any) => !loc.clientId)
        .sort((a: any, b: any) => a.nameEn?.localeCompare(b.nameEn)) || []
    );
  }, [locationsData]);

  return (
    <Box mt={isSpecificWidth ? "-90px" : "-40px"}>
      <Box display={"flex"} justifyContent={"flex-end"} pt={"30px"} mb={3}>
        <Stack direction={"row"} gap={1}>
          <Button
            startIcon={<img src={correctIcon} alt="" />}
            variant="contained"
            size="small"
            onClick={handleOpen}
            disabled={status === "archived"}
          >
            Assign Existing Location
          </Button>
          <Button
            startIcon={<img src={assignIcon} alt="" />}
            variant="contained"
            size="small"
            onClick={handleNavigateToAddLocation}
            disabled={status === "archived"}
          >
            Add New
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "10px 30px 20px 30px",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <TableContainer sx={{}}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeadText.map((item) => (
                  <TableCell
                    key={item}
                    sx={{
                      fontWeight: 600,
                      color: "#A1A5B7",
                    }}
                    align={item === "Actions" ? "right" : "left"}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {false ? (
              <TableLoadingSkeleton rowsLength={4} cellsLength={2} />
            ) : (
              <TableBody>
                {locations.map((row) => {
                  const hasValetService = !!row.services?.find(
                    (service) => service.type === "valet"
                  );
                  const hasSelfParkingService = !!row.services?.find(
                    (service) => service.type === "selfParking"
                  );
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.nameEn}</TableCell>
                      <TableCell>{row.nameAr}</TableCell>
                      <TableCell>
                        <StatusChip
                          color={hasValetService ? "success" : "warning"}
                          label={hasValetService ? "ACTIVE" : "INACTIVE"}
                        />
                      </TableCell>
                      <TableCell>
                        <StatusChip
                          label={hasSelfParkingService ? "ACTIVE" : "INACTIVE"}
                          color={hasSelfParkingService ? "success" : "warning"}
                        />
                      </TableCell>
                      <TableCell>
                        <TableActions
                          rowId={row.id as string}
                          disabled={status === "archived"}
                          refetch={refetch}
                          refetchLocations={refetchLocations}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {locations.length === 0 && <NoData subtitle="" />}
        </TableContainer>
      </Box>

      {open && (
        <AssignLocationPopup
          refetch={refetch}
          open={open}
          onClose={handleClose}
          locations={sortedLocations}
          refetchLocations={refetchLocations}
          isFetchingLocations={isFetchingLocations}
        />
      )}
    </Box>
  );
};

export default Locations;
