import * as yup from "yup";

const englishRegex = /^[A-Za-z0-9\s]+$/; // Matches English letters, numbers, and spaces
const arabicRegex = /^[\u0600-\u06FF0-9\s]+$/; // Matches Arabic letters, numbers, and spaces

export const schema = yup.object().shape({
  nameEn: yup
    .string()
    .required("Required")
    .max(100, "Can not exceed 100 characters"),
  nameAr: yup.string().max(100, "Can not exceed 100 characters"),
  agreementType: yup.string().required("Required"),
  startDate: yup.date().required("Required"),
  endDate: yup
    .date()
    .nullable() // Allows empty endDate
    .test(
      "is-after-start",
      "End date must be after start date",
      function (endDate) {
        const { startDate } = this.parent;
        if (!endDate) return true; // If endDate is not provided, it's valid
        return endDate > startDate; // Validate endDate is after startDate
      }
    ),
  contacts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Required")
          .max(100, "Can not exceed 100 characters"),
        position: yup.string().max(100, "Can not exceed 100 characters"),
        mobileNumber: yup
          .string()
          .max(100, "Can not exceed 100 characters"),
        email: yup
          .string()
          .required("Required")
          .max(100, "Can not exceed 100 characters")
          .test("is-valid-email", "Invalid Email", (value) => {
            if (!value) return true;
            const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
            return emailRegex.test(value);
          }),
      })
    )
    .required("Choose related payments is required"),
  accounts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Required")
          .max(100, "Can not exceed 100 characters"),
        email: yup
          .string()
          .required("Required")
          .max(100, "Can not exceed 100 characters")
          .test("is-valid-email", "Invalid Email", (value) => {
            if (!value) return true;
            const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
            return emailRegex.test(value);
          }),
      })
    )
    .required("Choose related payments is required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}
