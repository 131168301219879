import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "src/assets/svg-icons/close.svg";
import CarIcon from "src/assets/svg-icons/car.svg";
import PersonIcon from "src/assets/svg-icons/person.svg";
import moment from "moment";
import InfoItem from "../info-item";

interface IProps {
  open: boolean;
  onClose: any;
  transDetails: any;
}

const TransactionsDetailsDrawer = ({ open, onClose, transDetails }: IProps) => {
  console.log({ transDetails });
  const checkInEmployee = transDetails?.involvedEmployees?.find(
    (item: any) => item.operationType === "checkin"
  );
  const checkOutEmployee = transDetails?.involvedEmployees?.find(
    (item: any) => item.operationType === "checkout"
  );

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box minWidth={{ xs: "100vw", md: "540px" }} padding={"40px 30px"}>
        {/* header */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #454343"}
          paddingBottom={"29px"}
        >
          <Typography>Transaction Details</Typography>
          <IconButton sx={{ p: "0" }} onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </IconButton>
        </Stack>
        {/* content */}
        <Box paddingY={"26px"}>
          <Stack gap={2.5}>
            {/* ticket details */}
            <InfoItem label="#Ticket-Num" value={transDetails.ticketNumber} />
            <InfoItem label="Location" value={transDetails.locationName} />
            <InfoItem
              label="Service Type"
              value={
                transDetails.service?.type === "valet"
                  ? "Valet"
                  : "Self Parking"
              }
            />

            <InfoItem
              label="Total Parking Fees"
              value={
                transDetails.totalPrice >= 0
                  ? `${transDetails.totalPrice} SAR`
                  : "--"
              }
            />

            {(transDetails.paymentDetails || [])
              .filter((payment: any) => !!payment) // to avoid null weird case
              .map((payment: any) => (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <InfoItem
                      label="Payment Fees"
                      value={
                        payment?.amount >= 0 ? `${payment.amount} SAR` : "--"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoItem
                      label="Payment Time"
                      value={
                        payment?.time
                          ? moment(payment.time).format("DD-MM-YY hh:mm A")
                          : "--"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoItem
                      label="Payment Method"
                      value={payment?.paymentMethod || "--"}
                    />
                  </Grid>
                </Grid>
              ))}

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <InfoItem
                  label="Check in Date"
                  value={
                    transDetails.checkInTime
                      ? moment(transDetails.checkInTime).format("D/M/YYYY")
                      : "--"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoItem
                  label="Check out Date"
                  value={
                    transDetails.checkOutTime
                      ? moment(transDetails.checkOutTime).format("D/M/YYYY")
                      : "--"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <InfoItem
                  label="Check in Time"
                  value={
                    transDetails.checkInTime
                      ? moment(transDetails.checkInTime).format("h:mm A")
                      : "--"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoItem
                  label="Check out Time"
                  value={
                    transDetails.checkOutTime
                      ? moment(transDetails.checkOutTime).format("h:mm A")
                      : "--"
                  }
                />
              </Grid>
            </Grid>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* car details */}
            <Stack direction={"row"} gap={1}>
              <img src={CarIcon} alt="" />
              <Typography>Car Details</Typography>
            </Stack>
            <InfoItem
              label="#Plate-Num"
              value={
                transDetails.plateInfo
                  ? `${transDetails.plateInfo?.letters || ""}${
                      transDetails.plateInfo?.numbers || ""
                    }`
                  : "--"
              }
            />
            <Stack direction={"row"} justifyContent={"space-between"}>
              <InfoItem
                label="Vehicle Description"
                value={transDetails.carInfo?.description || "--"}
              />
            </Stack>
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            {/* customer details */}
            <Stack direction={"row"} gap={1}>
              <img src={PersonIcon} alt="" />
              <Typography>Customer Details</Typography>
            </Stack>
            <InfoItem label="Name" value={transDetails.customerName || "--"} />
            <InfoItem
              label="Mobile Number"
              value={
                transDetails.customerNumber?.mobile
                  ? `${transDetails.customerNumber?.countryCode ?? ""} ${
                      transDetails.customerNumber?.mobile?.length > 20
                        ? transDetails.customerNumber?.mobile.substring(0, 20) +
                          "..."
                        : transDetails.customerNumber?.mobile
                    }`
                  : "--"
              }
            />
            <Box bgcolor={"#454343"} height={"1px"} width={"100%"} />
            <InfoItem
              label="Check in Employee"
              value={checkInEmployee?.userNameEn || "--"}
            />
            <InfoItem
              label="Check out Employee"
              value={checkOutEmployee?.userNameEn || "--"}
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default TransactionsDetailsDrawer;
