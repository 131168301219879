import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DateRangeInput from "src/shared/components/date-range";
import { useMemo, useState } from "react";
import moment from "moment";
import ExperienceStatistics from "./experience-statistics";
import ServiceCommentsTable from "./service-comments";
import EmployeesCommentsTable from "./employees-comments";
import { useGetCitiesList } from "src/api/general";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ICity } from "src/types/generic";
import { useGetLocationsList } from "src/api/locations";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const rateOptions = ["1", "2", "3", "4", "5"];

const CustomersFeedback = () => {
  const [tempDateRange, setTempDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "", // moment().startOf("month").format("yyyy-MM-DD"),
    endDate: "", //moment().endOf("day").format("yyyy-MM-DD"),
  });

  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "", // moment().startOf("month").format("yyyy-MM-DD"),
    endDate: "", //moment().endOf("day").format("yyyy-MM-DD"),
  });
  const [tempSelectedLocations, setTempSelectedLocations] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [tempSelectedCities, setTempSelectedCities] = useState<ICity[]>([]);
  const [selectedCities, setSelectedCities] = useState<ICity[]>([]);
  const [tempRates, setTempRates] = useState<string[]>([]);
  const [rates, setRates] = useState<string[]>([]);

  // APIS
  const { data: citiesData } = useGetCitiesList({
    type: "active",
  });
  const { data: locationsData } = useGetLocationsList();

  const handleChangeCities = (value: any[]) => {
    console.log("change", { value });
    setTempSelectedCities(value);
  };

  const handleChangeLocations = (value: any[]) => {
    console.log("change", { value });
    setTempSelectedLocations(value);
  };

  const handleChangeRates = (event: SelectChangeEvent<typeof tempRates>) => {
    const {
      target: { value },
    } = event;
    setTempRates(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleApply = () => {
    setSelectedCities(tempSelectedCities);
    setDateRange(tempDateRange);
    setRates(tempRates);
    setSelectedLocations(tempSelectedLocations);
  };

  const citiesIds = useMemo(
    () => selectedCities.map((city) => city.id),
    [selectedCities]
  );

  const locationsIds = useMemo(
    () => selectedLocations.map((location) => location.id),
    [selectedLocations]
  );

  const sortedLocations = useMemo(() => {
    return (
      locationsData?.locations.sort((a: any, b: any) =>
        a.nameEn?.localeCompare(b.nameEn)
      ) || []
    );
  }, [locationsData]);

  console.log({ selectedCities, dateRange });

  return (
    <Box p={"30px"}>
      <Typography fontSize={"22px"} fontWeight={600} color={"#F0F0F0"} mb={4}>
        Customers Feedback
      </Typography>

      <Box mt={3} mb={"29px"}>
        <Stack direction={"row"} gap={{ xs: 1, md: 2 }} flexWrap={"wrap"}>
          {/* cities */}
          <Autocomplete
            sx={{ width: { xs: "100%", sm: "20%" } }}
            size="small"
            limitTags={1}
            multiple
            id="tags-outlined"
            options={citiesData?.data || []}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.nameEn}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Typography>{option.nameEn}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cities" placeholder="" />
            )}
            onChange={(event, value) => {
              handleChangeCities(value);
            }}
          />
          {/* locations */}
          <Autocomplete
            sx={{ width: { xs: "100%", sm: "20%" } }}
            size="small"
            limitTags={1}
            multiple
            id="tags-outlined"
            options={sortedLocations}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.nameEn}
            getOptionKey={(option: any) => option.id}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={`${props.id} ${option.id}`}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Typography>{option.nameEn}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Location" placeholder="" />
            )}
            onChange={(event, value) => {
              handleChangeLocations(value);
            }}
          />
          {/* rates */}
          <FormControl
            size="small"
            fullWidth
            sx={{
              width: { xs: "100%", sm: "15%" },
            }}
          >
            <InputLabel id="demo-multiple-checkbox-label">Rates</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={tempRates}
              onChange={handleChangeRates}
              input={<OutlinedInput label="Rates" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {rateOptions.map((rate) => (
                <MenuItem key={rate} value={rate}>
                  <Checkbox checked={tempRates.indexOf(rate) > -1} />
                  <ListItemText primary={rate} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* date range */}
          <Box
            sx={{
              width: { xs: "100%", sm: "max-content" },
            }}
          >
            <DateRangeInput
              startDate={
                tempDateRange.startDate ? moment(tempDateRange.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={
                tempDateRange.endDate ? moment(tempDateRange.endDate) : null
              } // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setTempDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleApply}
            sx={{ minWidth: "160px", ml: "auto" }}
          >
            Apply
          </Button>
        </Stack>
      </Box>

      <ExperienceStatistics
        cities={citiesIds}
        locations={locationsIds}
        dateRange={dateRange}
        rates={rates}
      />

      <Box my={2}>
        <ServiceCommentsTable
          cities={citiesIds}
          locations={locationsIds}
          dateRange={dateRange}
          rates={rates}
        />
      </Box>

      <Box>
        <EmployeesCommentsTable
          cities={citiesIds}
          locations={locationsIds}
          dateRange={dateRange}
          rates={rates}
        />
      </Box>
    </Box>
  );
};

export default CustomersFeedback;
