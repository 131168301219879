import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";
import {
  IClientBody,
  IClientResponse,
  IContactPerson,
  IUserAccount,
} from "src/types/clients";

interface IParams {
  type?: "active" | "archived";
}

export const useGetClientsList = (params?: IParams) =>
  useQuery<void, APIErrorResponse, { data: IClientResponse[] }>({
    queryKey: ["get-clients-list", params?.type],
    queryFn: async () => {
      return apiClient.get(`/client`, { params: params ?? {} });
    },
    gcTime: 0,
  });

export const useAddClientMutation = () =>
  useMutation<any, APIErrorResponse, IClientBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/client`, data);
    },
  });

export const useUpdateClientMutation = () =>
  useMutation<any, APIErrorResponse, IClientBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.patch(`/client/${id}`, rest);
    },
  });

export const useGetClientById = ({ id }: { id: string }) =>
  useQuery<any, APIErrorResponse, { data: IClientResponse }>({
    queryKey: ["get-client-by-id"],
    queryFn: async () => {
      return apiClient.get(`/client/${id}`);
    },
    gcTime: 0,
  });

// user accounts
export const useAddClientAccountMutation = () =>
  useMutation<any, APIErrorResponse, IUserAccount>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.post(`/client/${id}/linkedAccounts`, rest);
    },
  });

export const useDeleteClientAccountMutation = () =>
  useMutation<any, APIErrorResponse, { clientId: string; accountId: string }>({
    mutationFn: async (data) => {
      const { clientId, accountId } = data;
      return apiClient.delete(
        `/client/${clientId}/linkedAccounts/${accountId}`
      );
    },
  });

// contact persons
interface IContactPersonWithClientId extends IContactPerson {
  clientId: string;
}

export const useAddContactPersonMutation = () =>
  useMutation<any, APIErrorResponse, IContactPersonWithClientId>({
    mutationFn: async (data) => {
      const { clientId, ...rest } = data;
      return apiClient.post(`/client/${clientId}/contactPersons`, rest);
    },
  });

export const useUpdateContactPersonMutation = () =>
  useMutation<any, APIErrorResponse, IContactPersonWithClientId>({
    mutationFn: async (data) => {
      const { id, clientId, ...rest } = data;
      return apiClient.patch(`/client/${clientId}/contactPersons/${id}`, rest);
    },
  });

export const useDeleteContactPersonMutation = () =>
  useMutation<any, APIErrorResponse, { clientId: string; contactId: string }>({
    mutationFn: async (data) => {
      const { clientId, contactId } = data;
      return apiClient.delete(
        `/client/${clientId}/contactPersons/${contactId}`
      );
    },
  });

// assign locations
export const useAssignLocationsToClientMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    { clientId: string; locationIds: string[] }
  >({
    mutationFn: async (data) => {
      const { clientId, ...rest } = data;
      return apiClient.post(`/client/${clientId}/locations`, rest);
    },
  });

export const useUnassignLocationFromClientMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    { clientId: string; locationId: string }
  >({
    mutationFn: async (data) => {
      const { clientId, locationId } = data;
      return apiClient.delete(`/client/${clientId}/locations/${locationId}`);
    },
  });

export const useArchiveClientMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    { clientId: string; endDate: string; archivingNote?: string }
  >({
    mutationFn: async ({ clientId, ...rest }) => {
      return apiClient.put(`/client/${clientId}/archive`, rest);
    },
  });

export const useActivateClientMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    { clientId: string; startDate: string; note?: string }
  >({
    mutationFn: async ({ clientId, ...rest }) => {
      return apiClient.put(`/client/${clientId}/unarchive`, rest);
    },
  });
