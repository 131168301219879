import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import { Box, Button } from "@mui/material";
import assignIcon from "src/assets/svg-icons/assign.svg";
import AddEditContactPersonPopup from "./add-edit-contact-popup";
import { IContactPerson } from "src/types/clients";

const tableHeadText = ["Name", "Position", "Phone number", "Email", "Actions"];

interface IProps {
  contactPersons: IContactPerson[];
  refetch: Function;
  status: "archived" | "active";
}

const ContactPersons = (props: IProps) => {
  const { contactPersons, refetch, status } = props;
  const [open, setOpen] = useState(false);
  const noData = contactPersons.length === 0;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box mt={{ xs: "-40px", lg: "-90px" }}>
      <Box display={"flex"} justifyContent={"flex-end"} pt={"30px"} mb={3}>
        <Button
          size={"small"}
          startIcon={<img src={assignIcon} alt="" />}
          variant="contained"
          onClick={handleOpen}
          disabled={contactPersons.length >= 5 || status === "archived"}
        >
          Add New Contact Person
        </Button>
      </Box>
      <TableContainer
        sx={{
          bgcolor: "#1A1A1A",
          boxShadow: "0px 14px 44px 0px rgba(0, 0, 0, 0.35)",
          padding: "30px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  sx={{
                    fontWeight: 600,
                    color: "#A1A5B7",
                  }}
                  align={item === "Actions" ? "right" : "left"}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {false ? (
            <TableLoadingSkeleton rowsLength={4} cellsLength={2} />
          ) : (
            <TableBody>
              {contactPersons.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>{row.mobileNumber}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell align="right">
                    <TableActions
                      refetch={refetch}
                      row={row}
                      disabled={status === "archived"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {noData && <NoData subtitle="" />}

        {open && (
          <AddEditContactPersonPopup
            open={open}
            onClose={handleClose}
            refetch={refetch}
          />
        )}
      </TableContainer>
    </Box>
  );
};
export default ContactPersons;
