import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import MainInfo from "./info";
import ClientProfileTabs from "./tabs";
import { useGetClientById } from "src/api/clients";
import { useLocation, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";

const ClientProfilePage = () => {
  const { id } = useParams();
  const location = useLocation();
  const { data, refetch, isLoading } = useGetClientById({
    id: id ?? "",
  });

  const isComingFromActiveClientsPage = location.pathname.includes("active");

  const {
    id: clientId = "",
    clientInfo,
    contactPersons = [],
    userAccounts = [],
    locations = [],
  } = data?.data || {};

  const mainInfo = {
    agreementTypeLabel: clientInfo?.agreementTypeLabel || "",
    startDate: clientInfo?.startDate || "",
    endDate: clientInfo?.endDate || "",
    nameAr: clientInfo?.nameAr || "",
    nameEn: clientInfo?.nameEn || "",
  };

  const breadcrumbs = [
    <Link
      color={"#A1A5B7"}
      component={RouterLink}
      underline="hover"
      to={
        isComingFromActiveClientsPage ? "/clients/active" : "/clients/archived"
      }
    >
      {isComingFromActiveClientsPage ? "Active" : "Archived"} Clients
    </Link>,
    <Typography key="3" color="#A1A5B7">
      {clientInfo?.nameEn}
    </Typography>,
  ];

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"600px"}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={{ xs: "30px 20px", lg: "30px 0 30px 20px" }} height={"100%"}>
      <Typography mb={0.5} fontSize={"22px"} fontWeight={600}>
        {clientInfo?.nameEn}
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: "#5E6278", mb: 3 }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Grid container height={"90%"}>
        <Grid
          item
          xs={12}
          lg={3.4}
          bgcolor={"#232323"}
          borderRadius={"10px"}
          px={2}
          py={3}
          mb={{ xs: 3, lg: 0 }}
        >
          <MainInfo
            mainInfo={mainInfo}
            refetch={refetch}
            clientStatus={clientInfo?.status as "active" | "archived"}
          />
        </Grid>
        <Grid item xs={0} lg={0.2} />
        <Grid item xs={12} lg={8.2}>
          <ClientProfileTabs
            contactPersons={contactPersons}
            userAccounts={userAccounts}
            locations={locations}
            refetch={refetch}
            status={clientInfo?.status as "active" | "archived"}
            clientName={mainInfo.nameEn}
            agreementType={clientInfo?.agreementType as string}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientProfilePage;
