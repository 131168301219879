import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { FormInputs, schema } from "./constants";
import DeleteIcon from "src/assets/svg-icons/delete.svg";
import AddIcon from "src/assets/svg-icons/add.svg";
import ConfirmPopup from "src/shared/components/confirmation-popup";
import { useAddClientMutation } from "src/api/clients";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "src/firebase";
import { IEnumData } from "src/types/generic";

const breadcrumbs = [
  <Link
    color={"#A1A5B7"}
    component={RouterLink}
    underline="hover"
    to={"/clients/active"}
  >
    Active Clients
  </Link>,
  <Typography key="3" color="#A1A5B7">
    Add New Client
  </Typography>,
];

const AddEditClientsPage = () => {
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [agreementTypes, setAgreementTypes] = useState<IEnumData[]>([]);

  const methods = useForm<FormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      contacts: [
        {
          name: "",
          position: "",
          mobileNumber: "",
          email: "",
        },
      ],
      accounts: [],
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    name: "contacts",
    control,
  });

  const {
    fields: accountsFields,
    append: appendAccount,
    remove: removeAccount,
  } = useFieldArray({
    name: "accounts",
    control,
  });

  // APIS
  const { mutate, isPending, status, error, data } = useAddClientMutation();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const {
      nameEn,
      nameAr,
      agreementType,
      startDate,
      endDate,
      contacts,
      accounts,
    } = data;

    const dataToSend = {
      clientInfo: {
        nameEn,
        nameAr,
        agreementType,
        startDate: `${startDate.getTime()}`,
        endDate: endDate ? `${endDate.getTime()}` : "",
      },
      contactPersons: contacts.map((contact) => {
        return {
          ...contact,
          mobileNumber: !!contact.mobileNumber?.trim()
            ? `${contact.mobileNumber?.trim()}`
            : null,
        };
      }),
      userAccounts: accounts,
    };

    console.log({ dataToSend });
    mutate(dataToSend);
  };

  const addContact = () => {
    if (fields.length >= 5) return;
    append({
      name: "",
      position: "",
      mobileNumber: "",
      email: "",
    });
  };

  const deleteContact = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    remove(index);
  };

  const addAccount = () => {
    if (accountsFields.length >= 5) return;
    appendAccount({
      name: "",

      email: "",
    });
  };

  const deleteAccount = (index: number) => {
    if (accountsFields.length === 1) {
      return;
    }
    removeAccount(index);
  };

  const handleOpenDatePicker = () => setOpenDate(true);
  const handleCloseDatePicker = () => setOpenDate(false);
  const handleOpenEndDatePicker = () => setOpenEndDate(true);
  const handleCloseEndDatePicker = () => setOpenEndDate(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleBackToActiveList = () => {
    navigate("/clients/active");
  };

  useEffect(() => {
    if (watch("agreementType") === "revenueSharing") {
      appendAccount({
        name: "",
        email: "",
      });
    } else {
      removeAccount();
    }
  }, [watch("agreementType")]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The client has been added successfully!",
        },
      ]);
      navigate(`/clients/active/${data?.data.clientId}`);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  // Fetch agreement types enum
  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchData = async () => {
      try {
        const docRef = doc(db, "enums", "clientAgreementTypes");

        unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Fetched enum data:", data);
            setAgreementTypes(data.data);
          } else {
            console.warn("No such enum document found!");
          }
        });
      } catch (error) {
        console.error("Error fetching enum:", error);
      }
    };

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  console.log({ agreementTypes, errors });

  return (
    <Box p={"30px"}>
      <Typography mb={1} fontSize={"22px"} fontWeight={600}>
        Add New Client
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: "#5E6278", mb: 3 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          {/* Client Info */}
          <Box
            p={3}
            borderRadius={"10px"}
            border={"1.036px solid #5B5B5B"}
            mb={"33px"}
          >
            <Typography fontSize={"20px"} fontWeight={600} mb={3}>
              Client Info
            </Typography>

            <Grid container spacing={2} mb={3}>
              <Grid item xs={12} md={4}>
                <Controller
                  name="nameEn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Name in English"
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.nameEn}
                      helperText={errors.nameEn?.message}
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  name="nameAr"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Name in Arabic"
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.nameAr}
                      helperText={errors.nameAr?.message}
                      InputLabelProps={{
                        shrink: field.value !== undefined ? true : false,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.agreementType}
                >
                  <InputLabel id="demo-simple-select-label">
                    Agreement Type
                  </InputLabel>
                  <Controller
                    name="agreementType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Agreement Type"
                      >
                        {agreementTypes.map((agreement) => (
                          <MenuItem key={agreement.key} value={agreement.key}>
                            {agreement.label.en}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.agreementType?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Start Date"
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: !!errors.startDate,
                          helperText: errors.startDate?.message,
                          onClick: handleOpenDatePicker,
                          onBlur: () => {
                            if (!openDate) {
                              field.onBlur();
                            }
                          },
                          inputProps: { readOnly: true },
                        },
                      }}
                      format={"dd/MM/yyyy"}
                      open={openDate}
                      onOpen={handleOpenDatePicker}
                      onClose={handleCloseDatePicker}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="End Date"
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          error: !!errors.endDate,
                          helperText: errors.endDate?.message,
                          onClick: handleOpenEndDatePicker,
                          onBlur: () => {
                            if (!openEndDate) {
                              field.onBlur();
                            }
                          },
                          inputProps: { readOnly: true },
                        },
                      }}
                      format={"dd/MM/yyyy"}
                      open={openEndDate}
                      onOpen={handleOpenEndDatePicker}
                      onClose={handleCloseEndDatePicker}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {/* contact person */}
          <Box
            p={3}
            borderRadius={"10px"}
            border={"1.036px solid #5B5B5B"}
            mb={"33px"}
          >
            <Typography fontSize={"20px"} fontWeight={600} mb={3}>
              Contact Persons
            </Typography>
            <Stack gap={2}>
              {fields.map((field, index) => (
                <Stack
                  key={field.id}
                  direction={"row"}
                  spacing={"19px"}
                  alignItems={"center"}
                >
                  <Box
                    p={3}
                    borderRadius={"10px"}
                    border={"1.036px solid #5B5B5B"}
                    mb={"33px"}
                    flexGrow={1}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={3}>
                        <Controller
                          name={`contacts.${index}.name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label="Name"
                              fullWidth
                              size="small"
                              {...field}
                              error={!!errors.contacts?.[index]?.name}
                              helperText={
                                errors.contacts?.[index]?.name?.message
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Controller
                          name={`contacts.${index}.position`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label="Position"
                              fullWidth
                              size="small"
                              {...field}
                              error={!!errors.contacts?.[index]?.position}
                              helperText={
                                errors.contacts?.[index]?.position?.message
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Controller
                          name={`contacts.${index}.mobileNumber`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label="Mobile Number"
                              fullWidth
                              size="small"
                              {...field}
                              error={!!errors.contacts?.[index]?.mobileNumber}
                              helperText={
                                errors.contacts?.[index]?.mobileNumber?.message
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Controller
                          name={`contacts.${index}.email`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label="Email"
                              fullWidth
                              size="small"
                              {...field}
                              error={!!errors.contacts?.[index]?.email}
                              helperText={
                                errors.contacts?.[index]?.email?.message
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Stack>
                    {fields.length > 1 && (
                      <IconButton onClick={() => deleteContact(index)}>
                        <img src={DeleteIcon} alt="" />
                      </IconButton>
                    )}

                    {index === fields.length - 1 && fields.length < 5 && (
                      <IconButton onClick={addContact}>
                        <img src={AddIcon} alt="" />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>

          {/* accounts */}
          {accountsFields.length > 0 && (
            <Box
              p={3}
              borderRadius={"10px"}
              border={"1.036px solid #5B5B5B"}
              mb={"33px"}
            >
              <Typography fontSize={"20px"} fontWeight={600} mb={3}>
                Client Users Accounts
              </Typography>
              <Stack gap={2}>
                {accountsFields.map((field, index) => (
                  <Stack
                    key={field.id}
                    direction={"row"}
                    spacing={"19px"}
                    alignItems={"center"}
                  >
                    <Box
                      p={3}
                      borderRadius={"10px"}
                      border={"1.036px solid #5B5B5B"}
                      mb={"33px"}
                      flexGrow={1}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`accounts.${index}.name`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="Name"
                                fullWidth
                                size="small"
                                {...field}
                                error={!!errors.accounts?.[index]?.name}
                                helperText={
                                  errors.accounts?.[index]?.name?.message
                                }
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            name={`accounts.${index}.email`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="Email"
                                fullWidth
                                size="small"
                                {...field}
                                error={!!errors.accounts?.[index]?.email}
                                helperText={
                                  errors.accounts?.[index]?.email?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Stack>
                      {accountsFields.length > 1 && (
                        <IconButton onClick={() => deleteAccount(index)}>
                          <img src={DeleteIcon} alt="" />
                        </IconButton>
                      )}

                      {index === accountsFields.length - 1 &&
                        accountsFields.length < 5 && (
                          <IconButton onClick={addAccount}>
                            <img src={AddIcon} alt="" />
                          </IconButton>
                        )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
          )}

          <Box display={"flex"} justifyContent={"flex-end"}>
            <Stack
              mt={3}
              spacing={2}
              direction={"row"}
              sx={{ width: { xs: "100%", lg: "426px" } }}
            >
              <Button variant="outlined" fullWidth onClick={handleOpenPopup}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={isPending}
              >
                Add Client
              </LoadingButton>
            </Stack>
          </Box>
        </FormProvider>
      </form>

      <ConfirmPopup
        open={openPopup}
        setOpen={setOpenPopup}
        handleClose={handleClosePopup}
        subtitle="Are you sure you want to cancel creating this client and discard changes?"
        submitBtnLabel="Yes"
        cancelBtnLabel="No"
        onSave={handleBackToActiveList}
        isLoading={false}
        withNote={false}
        title="Confirmation message"
      />
    </Box>
  );
};

export default AddEditClientsPage;
