import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import CloseIcon from "src/assets/svg-icons/close.svg";
import { useGetLocationsList } from "src/api/locations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import { useAssignLocationsToClientMutation } from "src/api/clients";
import { useParams } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  open: boolean;
  onClose: any;
  refetch: Function;
  locations: any[];
  refetchLocations: Function;
  isFetchingLocations: boolean;
}

const AssignLocationPopup: React.FC<IProps> = (props) => {
  const { id: clientId } = useParams();
  const {
    open,
    onClose,
    refetch,
    refetchLocations,
    locations,
    isFetchingLocations,
  } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [NoSelectionError, setNoSelectionError] = useState(false);

  // APIS
  const { mutate, status, isPending, error } =
    useAssignLocationsToClientMutation();

  const handleClose = () => {
    onClose();
    setNoSelectionError(false);
  };

  const handleChangeLocations = (value: any[], reason: string) => {
    setSelectedLocations(value);
    if (value.length === 0) {
      setNoSelectionError(true);
    } else {
      setNoSelectionError(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log({ selectedLocations });
    if (selectedLocations.length === 0) {
      setNoSelectionError(true);
      return;
    }
    mutate({
      clientId: `${clientId}`,
      locationIds: selectedLocations.map((loc) => `${loc.id}`),
    });
  };

  //Assign success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      refetchLocations();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The locations have been assigned successfully!",
        },
      ]);
      handleClose();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      // onClose={handleClose}
      component={"div"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          bgcolor: "#191919",
        },
      }}
    >
      <DialogTitle sx={{ p: "30px 30px 20px 30px" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography fontSize={"22px"} fontWeight={600} lineHeight={"22px"}>
            Assign Location
          </Typography>
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
            <img src={CloseIcon} alt="" />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={(e) => onSubmit(e)}>
        <Stack px={"30px"} gap={"21px"} minHeight={"400px"}>
          <Stack alignItems={"flex-end"}>
            <Typography color={"#A1A5B7"}>
              ({selectedLocations.length} Selected)
            </Typography>
          </Stack>
          <Autocomplete
            loading={isFetchingLocations}
            multiple
            id="tags-outlined"
            options={locations}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.nameEn}
            getOptionKey={(option: any) => option.id}
            renderOption={(props, option, { selected }) =>
              isFetchingLocations ? null : (
                <li {...props} key={`${props.id} ${option.id}`}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography>{option.nameEn}</Typography>
                </li>
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={NoSelectionError}
                helperText={
                  NoSelectionError
                    ? "You must select one location at least"
                    : ""
                }
                label="Locations"
                placeholder=""
              />
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option: any, index: number) => (
                <Chip
                  size="small"
                  label={option.nameEn}
                  {...getTagProps({ index })}
                  key={option.id}
                />
              ))
            }
            onChange={(event, value, reason) => {
              handleChangeLocations(value, reason);
            }}
          />
        </Stack>

        <DialogActions sx={{ padding: "31px", gap: "12px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignLocationPopup;
