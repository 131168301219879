import { Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { authState } from "src/store/auth";
import ActivatePopup from "../../../activate-popup";

const TableActions = ({
  rowId,
  refetch,
}: {
  rowId: string;
  refetch: Function;
}) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth] = useRecoilState(authState);
  const { permissions } = auth;
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleNavigateToProfilePage = () => {
    navigate(`/clients/archived/${rowId}`);
  };

  return (
    <Stack direction={"row"}>
      {permissions?.locations.edit ? (
        <LoadingButton
          variant="contained"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          size="small"
          sx={{ fontSize: "12px", color: "#7E8299", fontWeight: 600 }}
        >
          Actions
        </LoadingButton>
      ) : null}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenPopup}>Activate</MenuItem>
        <MenuItem onClick={handleNavigateToProfilePage}>Preview</MenuItem>
      </Menu>

      {openPopup && (
        <ActivatePopup
          open={openPopup}
          onClose={handleClosePopup}
          refetch={refetch}
          clientId={rowId}
        />
      )}
    </Stack>
  );
};
export default TableActions;
