import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import ClosIcon from "src/assets/svg-icons/close.svg";
import { useUpdateClientMutation } from "src/api/clients";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";

export const schema = yup.object().shape({
  nameEn: yup
    .string()
    .required("Required")
    .max(100, "Can not exceed 100 characters"),
  nameAr: yup.string().max(100, "Can not exceed 100 characters"),
  startDate: yup.date().required("Required"),
  endDate: yup
    .date()
    .nullable() // Allows empty endDate
    .test(
      "is-after-start",
      "End date must be after start date",
      function (endDate) {
        const { startDate } = this.parent;
        if (!endDate) return true; // If endDate is not provided, it's valid
        return endDate > startDate; // Validate endDate is after startDate
      }
    ),
});

export interface IFormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  onClose: any;
  id: string;
  dataToEdit?: {
    agreementTypeLabel: string;
    startDate: string;
    endDate: string;
    nameAr: string;
    nameEn: string;
  };
  refetch: Function;
}

const EditMainInfoPopup: React.FC<IProps> = (props) => {
  const { open, onClose, dataToEdit, id, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDate, setOpenDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // APIs
  const { mutate, status, isPending, error } = useUpdateClientMutation();

  const handleClose = () => {
    onClose();
  };

  const handleOpenDatePicker = () => setOpenDate(true);
  const handleCloseDatePicker = () => setOpenDate(false);
  const handleOpenEndDatePicker = () => setOpenEndDate(true);
  const handleCloseEndDatePicker = () => setOpenEndDate(false);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    console.log("main", { data });
    const { startDate, endDate, nameEn, nameAr } = data;

    const dataToSend = {
      id,
      clientInfo: {
        nameEn,
        nameAr,
        startDate: `${startDate.getTime()}`,
        endDate: endDate ? `${endDate.getTime()}` : "",
      },
    };

    mutate(dataToSend);
  };

  useEffect(() => {
    if (!!dataToEdit) {
      const { nameEn, nameAr, startDate, endDate } = dataToEdit;
      reset({
        nameEn,
        nameAr,
        startDate: new Date(Number(startDate)),
        endDate: endDate ? new Date(Number(endDate)) : undefined,
      });
    }
  }, [dataToEdit]);

  //Update success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The client has been updated successfully!",
        },
      ]);
      handleClose();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  console.log({ errors, dataToEdit });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      component={"div"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          bgcolor: "#191919",
        },
      }}
      keepMounted={false}
    >
      <DialogTitle sx={{ p: "30px 30px 20px 30px" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography fontSize={"22px"} fontWeight={600} lineHeight={"22px"}>
            Edit Main Info
          </Typography>
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
            <img src={ClosIcon} alt="" />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px={"30px"} gap={"21px"}>
          <Controller
            name="nameEn"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name in English"
                fullWidth
                size="medium"
                {...field}
                error={!!errors.nameEn}
                helperText={errors.nameEn?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
          <Controller
            name="nameAr"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name in Arabic"
                fullWidth
                size="medium"
                {...field}
                error={!!errors.nameAr}
                helperText={errors.nameAr?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Start Date"
                {...field}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    error: !!errors.startDate,
                    helperText: errors.startDate?.message,
                    onClick: handleOpenDatePicker,
                    onBlur: () => {
                      if (!openDate) {
                        field.onBlur();
                      }
                    },
                    inputProps: { readOnly: true },
                  },
                }}
                format={"dd/MM/yyyy"}
                open={openDate}
                onOpen={handleOpenDatePicker}
                onClose={handleCloseDatePicker}
              />
            )}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="End Date"
                {...field}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    error: !!errors.endDate,
                    helperText: errors.endDate?.message,
                    onClick: handleOpenEndDatePicker,
                    onBlur: () => {
                      if (!openEndDate) {
                        field.onBlur();
                      }
                    },
                    inputProps: { readOnly: true },
                  },
                }}
                format={"dd/MM/yyyy"}
                open={openEndDate}
                onOpen={handleOpenEndDatePicker}
                onClose={handleCloseEndDatePicker}
              />
            )}
          />
        </Stack>

        <DialogActions sx={{ padding: "31px", gap: "12px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            Update
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditMainInfoPopup;
