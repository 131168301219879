import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import ClosIcon from "src/assets/svg-icons/close.svg";
import { useActivateClientMutation } from "src/api/clients";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

export const schema = yup.object().shape({
  date: yup.date().required("Required"),
  note: yup.string().max(500, "Can not exceed 500 characters"),
});

export interface IFormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  onClose: any;
  refetch: Function;
  clientId: string;
}

const ActivatePopup: React.FC<IProps> = (props) => {
  const { open, onClose, refetch, clientId } = props;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDate, setOpenDate] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // APIs
  const { mutate, isPending, status, error } = useActivateClientMutation();

  const handleClose = () => {
    onClose();
  };

  const handleOpenDatePicker = () => setOpenDate(true);
  const handleCloseDatePicker = () => setOpenDate(false);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    console.log("main", { data });
    const { date, note } = data;

    const dataToSend = {
      clientId: clientId as string,
      startDate: date ? `${date.getTime()}` : "",
      note,
    };

    mutate(dataToSend);
  };

  //Archive success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      navigate("/clients/archived");
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "The client has been activated successfully!",
        },
      ]);
      handleClose();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            error?.data?.errors?.[0]?.message || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  console.log({ errors });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      component={"div"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          bgcolor: "#191919",
        },
      }}
    >
      <DialogTitle sx={{ p: "30px 30px 20px 30px" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography fontSize={"22px"} fontWeight={600} lineHeight={"22px"}>
            Are you sure?
          </Typography>
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
            <img src={ClosIcon} alt="" />
          </IconButton>
        </Box>
        <Typography fontSize={"14px"} mt={1}>
          This action will move this client from the archived clients list to
          the active list.
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px={"30px"} gap={"21px"}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Start Date"
                {...field}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    error: !!errors.date,
                    helperText: errors.date?.message,
                    onClick: handleOpenDatePicker,
                    onBlur: () => {
                      if (!openDate) {
                        field.onBlur();
                      }
                    },
                  },
                }}
                format={"dd/MM/yyyy"}
                open={openDate}
                onOpen={handleOpenDatePicker}
                onClose={handleCloseDatePicker}
              />
            )}
          />

          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                label="Leave your note..."
                fullWidth
                multiline
                rows={3}
                size="medium"
                {...field}
                error={!!errors.note}
                helperText={errors.note?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
        </Stack>

        <DialogActions sx={{ padding: "31px", gap: "12px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            Activate
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ActivatePopup;
