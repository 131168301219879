import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { genericErrorHandler } from "src/utils/_genericErrorHandler";
import { authentication } from '../firebase';

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

// Axios interceptor to inject the token to requests
apiClient.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
  const currentUser = authentication.currentUser;

  if (currentUser) {
    try {
      const token = await currentUser.getIdToken(); 
      request.headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }
  
  return request;
});

// Axios interceptor to handle response error generally
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle errors globally using the provided genericErrorHandler
    genericErrorHandler(error);
    return Promise.reject(error.response);
  }
);

export default apiClient;
