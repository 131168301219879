import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiCloudClient from "./apiCloudClient";
import { ISalesSummaryReport } from "src/types/reports";

interface IParams {
  startDate?: string;
  endDate?: string;
  locations?: string[];
  services?: string[];
}

export const useGetSalesSummary = (params: IParams) =>
  useQuery<IParams, any, { data: ISalesSummaryReport }>({
    queryKey: [
      "get-sales-summary",
      params.startDate,
      params.endDate,
      params.locations,
      params.services,
    ],
    queryFn: async () => {
      return apiCloudClient.get(`/getSalesSummaryRequest`, { params: params });
    },
    enabled: !!params.startDate && !!params.endDate,
    gcTime: 2 * 60 * 60 * 1000, // 2 Hours
  });

interface IBody {
  data: {
    startDate?: string;
    endDate?: string;
    locations?: string[];
    services?: string[];
  };
}

export const useGetSalesSummaryMutation = () =>
  useMutation<any, APIErrorResponse, IBody>({
    mutationFn: async (data) => {
      return apiCloudClient.post(`/getSalesSummaryRequest`, data);
    },
  });
