import { IUserPermissions } from "src/types/generic";

export const formatBytes = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

// Function to get first private route that user have access to
export const getFirstPrivateRoute = (
  permissions: IUserPermissions
): string | undefined => {
  if (permissions?.reports.viewSalesSummary) {
    return "reports/sales";
  }
  if (permissions?.businessIntelligence.viewTransactions) {
    return "business-intelligence/trans";
  }
  if (permissions?.locations.view) {
    return "locations/active";
  }
  if (permissions?.transactions?.view) {
    return "transactions/upcoming";
  }
  if (permissions?.customersFeedback) {
    return "customers/feedback";
  }
  if (permissions?.arsannEmployees?.view) {
    return "employees/active";
  }

  // If none of the conditions are met, return undefined
  return undefined;
};

export const formatNumber = (input: number) => {
  // Convert input to a number
  const num = Number(input);

  // If it's not a valid number, return the input as is
  if (isNaN(num)) {
    return input;
  }

  // Otherwise, format the number with commas
  return new Intl.NumberFormat().format(num);
};

export const downloadFile = (file: any, type: string, name: string) => {
  // Convert file data to Blob
  const blob = new Blob([file], { type: `${type};charset=utf-8;` });
  // Create a link element
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();
  // Clean up
  document.body.removeChild(link);
};

export function getFirstAndLastLetters(sentence: string) {
  if (!sentence) {
    return "";
  }
  // Trim spaces and split the sentence into words
  let words = sentence.trim().split(/\s+/);

  // Get the first letter of the first word and the first letter of the last word
  let firstLetter = words[0][0];
  let lastLetter = words.length > 1 ? words[words.length - 1][0] : "";

  return firstLetter + lastLetter; // You can return them as a string or array as per need
}
